<template>
  <form @submit.prevent="onSubmit" class="no-mb no-mt">
    <div class="row">
      <div class="col-xs-12">
        <div
          class="form-group"
          :class="{ 'has-error': $v.form.email.$anyError }"
        >
          <label class="form-label">Email</label>
          <input
            type="email"
            v-model.trim="$v.form.email.$model"
            class="form-control"
            :class="{ 'is-invalid': submitted && $v.form.email.$error }"
          />
          <span v-if="submitted && !$v.form.email.email" class="error"
            ><label class="error"
              >Debes introducir un email válido.</label
            ></span
          >
          <span v-if="submitted && !$v.form.email.required" class="error"
            ><label class="error">Este campo es obligatorio.</label></span
          >
        </div>

        <div
          class="form-group"
          :class="{ 'has-error': $v.form.password.$anyError }"
        >
          <label class="form-label">Contraseña</label>
          <input
            type="password"
            v-model.trim="$v.form.password.$model"
            class="form-control"
            :class="{ 'is-invalid': submitted && $v.form.password.$error }"
          />
          <span v-if="submitted && !$v.form.password.required" class="error"
            ><label class="error">Este campo es obligatorio.</label></span
          >
        </div>


        <div class="text-center">
          <button
            type="submit"
            class="btn btn-primary mt-10 btn-corner right-15"
          >
            Iniciar sesión
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "LoginView",
  components: {},
  computed: {},
  data() {
    return {
      errors: [],
      submitted: false,
      form: {
        password: "",
        email: "",

      },
    };
  },
  validations: {
    form: {
      email: { required, email },
      password: { required },
    },
  },
  methods: {
    async onSubmit() {
      this.submitted = true;

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

    try {
        const user = await this.$moralis.User.logIn(this.form.email, this.form.password, { usePost: true });
        this.$store.commit("setEmail", user.get('email'));
        this.$store.commit("setUser", user)
        this.$router.push({name: "home"})
    } catch (error) {
    
        alert("Error: " + error.code + " " + error.message);
    }

    },
  },
};
</script>
